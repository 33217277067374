.foster_container {
  margin: 0;
  padding: 0;
  text-align: center;
  position: relative;
}

.foster_container img {
  width: 100%;
  height: 100%;
}

.foster_container button {
  font-weight: bold;
  padding: 1em;
  margin-bottom: 3em;
}

.foster_callToAction {
  margin-top: 5%;
}

.foster_callToAction h1 {
  color: #343a40;
  font-size: 35px;
}
.foster_callToAction h3 {
  color: #343a40;
  font-size: 16px;
}

@media only screen and (min-width: 768px) {
  .foster_container {
    background-color: #343a40;
    height: auto;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
  }

  .foster-left {
    width: 50%;
    justify-content: center;
  }

  .foster_image img {
    width: 100%;
    height: 100%;
  }

  .foster-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;
  }
  .foster_callToAction h1 {
    color: rgb(207, 202, 202);
    font-size: 30px;
  }
  .foster_callToAction h3 {
    color: rgb(207, 202, 202);
    font-size: 16px;
  }
}

@media only screen and (min-width: 1200px) {
  .foster_callToAction h1 {
    font-size: 50px;
  }
  .foster_callToAction h3 {
    font-size: 25px;
  }
}
