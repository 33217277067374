.hero_container {
  margin: 0;
  padding: 0;
  background-color: #343a40;
  position: relative;
}

.hero_image img {
  width: 100%;
  height: 100%;
}

.hero_callToAction {
  text-align: center;
  color: rgb(207, 202, 202);
}

.hero_callToAction h1 {
  font-size: 3.5em;
  font-weight: bold;
  text-align: center;
  margin: 0;
}
.hero_callToAction span {
  font-size: 2.25em;
  text-align: center;
  margin: 0 0 10px 0;
}
.hero_callToAction button {
  font-weight: bold;
  margin: 1em 0 2em 0;
  padding: 1em;
}
.hero_callToAction button:hover {
  background-color: orange;
}

@media only screen and (min-width: 900px) {
  .hero_image img {
    position: relative;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .hero_callToAction {
    text-align: right;
    position: absolute;
    top: 300px;
    right: 35px;
    z-index: 1;
    color: rgb(207, 202, 202);
  }

  .hero_callToAction h1 {
    font-size: 3.5em;
  }
  .hero_callToAction h3 {
    font-size: 2.5em;

    text-align: right;
  }
}

@media only screen and (min-width: 992px) {
  .hero_callToAction {
    text-align: right;
    position: absolute;
    top: 300px;
    right: 45px;
  }

  .hero_callToAction h1 {
    font-size: 4.5em;
  }
  .hero_callToAction h3 {
    font-size: 3em;
  }
}
@media only screen and (min-width: 1200px) {
  .hero_callToAction {
    text-align: right;
    position: absolute;
    top: 300px;
    right: 100px;
  }

  .hero_callToAction h1 {
    font-size: 5em;
  }
  .hero_callToAction h3 {
    font-size: 3.5em;
  }
}

@media only screen and (min-width: 1430px) {
  .hero_callToAction {
    text-align: right;
    position: absolute;
    top: 300px;
    right: 130px;
  }
}

@media only screen and (min-width: 1920px) {
  .hero_callToAction h1 {
    font-size: 6.5em;
  }
  .hero_callToAction h3 {
    font-size: 4.25em;
  }
}
