.vote-image-mobile {
  display: none;
}
.vote-text {
  display: none;
}
.vote-text-mobile {
  display: none;
}

.adoptionAgreementContainer {
  height: 55vh;
}

.home_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#root {
  max-width: 2024px;
  margin-left: auto;
  margin-right: auto;
}

.pointer {
  cursor: pointer;
}

/* BLOG MODULE */

.blog_card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 2px solid rgb(241, 16, 16);
  border-radius: 0.5rem;
}

.blogSummary {
  text-overflow: ellipsis;
  height: 125px;
  overflow: hidden;
}
.blogPost {
  text-overflow: ellipsis;
}

/* ADOPTION PAGE */
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-rows: 2fr 2fr 3fr;
  grid-template-areas:
    /* 'thank-you thank-you adopt-picture' */
    'adoption-process adoption-process adopt-picture'
    'adoption-process adoption-process adopt-picture'
    'apply-online downloadable-apps adopt-picture';

  height: 110vh;
  margin: 2em;
}

.thank-you {
  grid-area: thank-you;
  color: crimson;
  margin-bottom: 2rem;
}

.thank-you h1 {
  padding-bottom: 0;
  margin-bottom: 0;
}

.thank-you h5 {
  padding-top: 0;
  font-weight: bolder;
}

.adoption-process {
  grid-area: adoption-process;
  padding: 1rem;
  margin-right: 1rem;
  background-color: #fdd365;
  border-radius: 2rem;
  margin-bottom: 2 rem;
}

.bold {
  font-weight: bold;
}

.roman-numerals {
  list-style-type: lower-roman;
}

.apply-online {
  margin-top: 1em;
  grid-area: apply-online;
}

.downloadable-apps {
  margin-top: 1em;
  grid-area: downloadable-apps;
  text-decoration: none !important;
}

/* Background for Admin NAVBAR and Admin them */
.bg-theme {
  background-color: #3d6cb9;
}
.bg-container {
  /* background-color: #212529; */
  background-color: #f5f5f5;
}
.bg-foster-theme {
  background-color: #3d6cb9;
  color: #f5f5f5;
}
.btn-background {
  background-color: #3d6cb9 !important;
  border-color: #3d6cb9 !important;
  color: #f5f5f5 !important;
}

.ck-editor__editable_inline {
  min-height: 200px;
}
.gt-modal {
  max-width: 650px !important;
}

.button-link:hover {
  text-decoration: none !important;
}

.carousel-control-next {
  z-index: 44;
}

.btn-orange {
  background-color: orange;
}
.btn-orange:hover {
  background-color: rgb(194, 149, 66);
}

.pet-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.card img {
  display: block;
  /* max-height: 300px; */
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  font-family: 'object-fit:cover';
  -o-object-fit: cover;
  object-fit: cover;
}
.border-thick {
  border-width: thick;
}

.border-10px {
  border-width: 10px !important;
}

.nav-link {
  font-size: 1.2em;
}

.item img {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 300px;
}

/* Extra Large */
@media screen and (max-width: 2000px) {
  .vote-image {
    max-width: 100px;
    margin: auto;
  }
  .vote-text {
    display: block;
  }
  .vote-text-mobile {
    display: none;
  }

  .vote-image-mobile {
    display: none;
  }
  .slide1 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(30%) translateY(-40%);
  }
  .slide1-display-2 {
    font-size: 5.5rem;
    line-height: 1;
  }
  .carousel-item {
    height: 700px;
  }
  .slide2 {
    /* position: absolute; */
    top: 50%;
    z-index: 1;
    transform: translateX(-40%) translateY(-50%);
  }
  .slide2-display-2 {
    font-size: 5rem;
    line-height: 1;
  }
  .slide2-subtitle {
    font-size: 1.75rem;
    color: white !important;
  }

  .carousel-item2 {
    height: 700px;
  }
  .slide3 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(-0%) translateY(-50%);
  }
  .slide3-display-2 {
    font-size: 5rem;
    line-height: 1;
  }
  .slide3-subtitle {
    font-size: 1.75rem;
  }

  .carousel-item3 {
    height: 700px;
  }
}
@media screen and (max-width: 1600px) {
  .vote-image {
    max-width: 100px;
    margin: auto;
  }
  .vote-image-mobile {
    display: none;
  }
  .vote-text {
    display: block;
  }
  .vote-text-mobile {
    display: none;
  }
  .slide1 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(30%) translateY(-40%);
  }
  .slide1-display-2 {
    font-size: 5.5rem;
    line-height: 1;
  }
  .carousel-item {
    height: 550px;
  }
  .slide2 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(-40%) translateY(-55%);
  }
  .slide2-display-2 {
    font-size: 3rem;
    line-height: 1;
    z-index: 1;
  }
  .slide2-subtitle {
    color: white !important;
    z-index: 1;
  }

  .carousel-item2 {
    height: 550px;
  }
  .slide3 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(-0%) translateY(-50%);
  }
  .slide3-display-2 {
    font-size: 5rem;
    line-height: 1;
  }
  .slide3-subtitle {
    font-size: 1.75rem;
  }

  .carousel-item3 {
    height: 550px;
  }
}
@media screen and (max-width: 1200px) {
  .vote-image {
    max-width: 100px;
  }
  .vote-text {
    display: block;
  }
  .vote-text-mobile {
    display: none;
  }
  .vote-image-mobile {
    display: none;
  }
  .slide1 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(30%) translateY(-50%);
  }
  .slide1-display-2 {
    font-size: 4rem;
    line-height: 1;
  }
  .sub-title-1 {
    font-size: 2rem;
  }
  .carousel-item {
    height: 550px;
  }
  .slide2 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(-40%) translateY(-55%);
  }
  .slide2-display-2 {
    font-size: 2.5rem;
    line-height: 1;
    color: white !important;
  }
  .slide2-subtitle {
    color: white !important;
  }
  .carousel-item2 {
    height: 550px;
  }
  .slide3 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(0%) translateY(-50%);
  }
  .slide3-display-2 {
    font-size: 5rem;
    line-height: 1;
  }
  .slide3-subtitle {
    font-size: 1.85rem;
  }

  .carousel-item3 {
    height: 550px;
  }
}

@media screen and (max-width: 992px) {
  .vote-image {
    display: none;
  }
  .vote-text {
    display: none;
  }

  .vote-image-mobile {
    max-width: 100px;
    display: block;
  }

  .vote-text-mobile {
    display: block;
  }

  .slide1 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(40%) translateY(-50%);
  }
  .slide1-display-2 {
    font-size: 3rem;
    line-height: 1;
  }
  .sub-title-1 {
    font-size: 1.3rem;
  }
  .carousel-item {
    height: 400px;
  }
  .slide2 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(-0%) translateY(-55%);
  }
  .slide2-display-2 {
    font-size: 2rem;
    line-height: 1;
    color: white !important;
  }
  .slide2-subtitle {
    color: white !important;
  }
  .carousel-item2 {
    height: 400px;
  }
  .slide3 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(0%) translateY(-50%);
  }
  .slide3-display-2 {
    font-size: 4rem;
    line-height: 1;
  }
  .slide3-subtitle {
    font-size: 1.25rem;
  }

  .carousel-item3 {
    height: 400px;
  }
  .contact-image {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .vote-image {
    display: none;
  }

  .vote-text {
    display: none;
  }
  .vote-text-mobile {
    display: block;
  }
  .vote-image-mobile {
    max-width: 100px;
    display: block;
  }
  .slide1 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(30%) translateY(-50%);
  }
  .slide1-display-2 {
    font-size: 2.5rem;
    line-height: 1;
  }
  .sub-title-1 {
    font-size: 1rem;
  }
  .carousel-item {
    height: 320px;
  }
  .slide2 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(0%) translateY(-50%);
  }
  .slide2-display-2 {
    font-size: 2rem;
    line-height: 1;
    color: white !important;
  }
  .slide2-subtitle {
    color: white !important;
  }
  .carousel-item2 {
    height: 320px;
  }
  .slide3 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(0%) translateY(-50%);
  }
  .slide3-display-2 {
    font-size: 2rem;
    line-height: 1;
  }
  .slide3-subtitle {
    font-size: 1rem;
  }

  .carousel-item3 {
    height: 320px;
  }
}

@media screen and (max-width: 576px) {
  .vote-image {
    display: none;
  }

  .vote-text {
    display: none;
  }
  .vote-text-mobile {
    display: block;
  }

  .vote-image-mobile {
    max-width: 100px;
    display: block;
  }
  .slide1 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(40%) translateY(-70%);
  }
  .slide1-display-2 {
    font-size: 2.5rem;
    line-height: 1;
  }
  .sub-title-1 {
    font-size: 1rem;
  }
  .carousel-item {
    height: 300px;
  }
  .slide2 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(0%) translateY(-50%);
  }
  .slide2-display-2 {
    font-size: 1.25rem;
    line-height: 1;
    color: white !important;
  }
  .slide2-subtitle {
    font-size: 0.75rem;
    color: white !important;
  }
  .carousel-item2 {
    height: 300px;
  }
  .slide3 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(0%) translateY(-50%);
  }
  .slide3-display-2 {
    font-size: 2rem;
    line-height: 1;
  }
  .slide3-subtitle {
    font-size: 1rem;
  }

  .carousel-item3 {
    height: 300px;
  }
}

@media screen and (max-width: 499px) {
  .slide1 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(30%) translateY(-80%);
  }
  .display-2 {
    font-size: 2rem;
    line-height: 1;
  }
  .sub-title-1 {
    font-size: 1rem;
  }
  .carousel-item {
    height: 200px;
  }
  .slide3 {
    /* position: absolute; */
    top: 50%;
    transform: translateX(0%) translateY(-50%);
  }
  .slide3-display-2 {
    font-size: 1.5rem;
    line-height: 1;
  }
  .slide3-subtitle {
    font-size: 0.85rem;
  }

  .carousel-item3 {
    height: 300px;
  }
}

/* Padding */
.p {
  padding: 0.5rem;
}
.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.py {
  padding: 0.5rem 0;
}
.py-1 {
  padding: 1rem 0;
}
.py-2 {
  padding: 2rem 0;
}
.py-3 {
  padding: 3rem 0;
}
.pl {
  padding-left: 0.5rem 0;
}
.pl-1 {
  padding-left: 1rem 0;
}
.pl-2 {
  padding-left: 2rem 0;
}
.pl-3 {
  padding-left: 3rem 0;
}
.pt-3 {
  padding-top: 3rem 0;
}

/* Margin */
.m {
  margin: 0.5rem;
}
.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.my {
  margin: 0.5rem 0;
}
.my-1 {
  margin: 1rem 0;
}
.my-2 {
  margin: 2rem 0;
}
.my-3 {
  margin: 3rem 0;
}

/* Grid */
.grid-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.grid-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.grid-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
}
