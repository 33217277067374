.chewyContainer {
  padding: 40px 0;
  background: grey;
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.chewyContainer p {
  max-width: 75%;
}

.chewyContainer button {
  font-weight: bold;
  padding: 1em;
  background: #d39e00;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
  border: none;
}

@media only screen and (min-width: 900px) {
  .chewyContainer {
    background: white;
    width: 100%;
    height: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .chewyContainer p {
    font-size: 20px;
    max-width: 60%;
  }
}
